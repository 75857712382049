import React from 'react';
import PropTypes from 'prop-types';
import './ProjectCard.css'
import rightArrow from '../../../media/arrow.right.png';
import { Link } from 'react-router-dom';

/**
 * The ProjectCard component will display some brief information about
 * one of the projects that the charity has completed.
 *
 * props:
 *  project - The project to display the information about.
 *            !!! Project must have properties: image, title, info !!!
 */
const ProjectCard = ({project}) => {

    const { imagePreview, title, info, comingSoon } = project;

    const imageStyle = comingSoon ? 'projectImage projectImageFilter' : 'projectImage';

    return (
        <Link className='projectCard' to='/project' state={{project: project}} data-testid='project-card'>
            <div className='projectImageContainer'>
                <img className={imageStyle} src={imagePreview} alt={imagePreview}/>
                { comingSoon && <h2 className='comingSoonLabel'> Coming Soon </h2> }
            </div>
            <h2 className='projectCardTitle'> {title} </h2>
            <p className='projectCardInfo'> {info} </p>
            <div className='readMoreContainer'>
                <div className='projectCardLinkContainer' to='/project' state={{project: project}}>
                    <h5 className='readMoreLabel'> Read more </h5>
                    <img className='rightArrowImage' src={rightArrow} alt="right arrow"/>
                </div>
            </div>
        </Link>
    );

}

ProjectCard.propTypes = {
    project: PropTypes.object
}

export default ProjectCard;
