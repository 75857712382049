import React from 'react';
import PropTypes from 'prop-types';
import './OurMissionSection.css';
import PageSection from '../PageSection/PageSection';
import { gray, white } from '../../helpers/colors';

/**
 * The Our Mission Section will display a quote with the mission
 * that the charity aims to achieve along with the author.
 *
 * props:
 *  missionStatement - The mission statement as a string.
 *  author - The author of the statement.
 */
const OurMissionSection = ({missionStatement, author}) => {
    return (
        <PageSection
        sectionTitle="Our Mission"
        backgroundColor={gray}
        foregroundColor={white}>
            <h2 className='missionStatement' data-testid='mission'> {missionStatement} </h2>
            <h4 className='authorText' data-testid='author'> {author} </h4>
        </PageSection>
    );
}

OurMissionSection.propTypes = {
    missionStatement: PropTypes.string,
    author: PropTypes.string
}

export default OurMissionSection;
