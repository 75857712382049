import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import emailjs from "@emailjs/browser"
import { getFormattedDate, getFormattedTime } from "../../helpers/utils"
import { addTicket, getTickets } from "../../services/ticketService"
import useContent from "../../hooks/useContent"
import PageSection from '../../components/PageSection/PageSection'
import { alternateGray, gray } from '../../helpers/colors'
import { bookingFee } from "../../helpers/constants"
import FindUsSection from '../../components/FindUsSection/FindUsSection'
import LoadWheel from "../../media/LoadWheel"
import Alert from "../../components/Alert/Alert"

import "./ConfirmationPage.css"

const ConfirmationPage = () => {

  const serviceId  = 'service_nkg1d5g'
  const templateId = 'template_kgg3ddf'
  const publicKey  = 'AmthsTXC8Qv3S4xb5'

  const [content] = useContent("Payment page", "PaymentPage")
  const [event, setEvent] = useState(undefined)
  const [bookingRef, setBookingRef] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [showError, setShowError] = useState({show: false, message: ""})
  const [ticketExists, setTicketExists] = useState(undefined)

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const bookingRef    = queryParams.get('bookingRef')

    if (bookingRef) {
      getTickets((tickets, error) => {
        if (error !== undefined) {
          setShowError({show: true, message: `An unexpected error occured: ${error.message}. Please contact us via our email admin@act-tanzania.org for support and we will assist you.`})
        } else {
          const ticketWithRef = tickets.filter((ticket) => ticket['bookingRef'] === bookingRef)
          setTicketExists(ticketWithRef.length !== 0)
        }
      })
    }
  }, [])

  useEffect(() => {
    const createTicket = async (customerName, customerEmail, event, bookingRef) => {
      try {
        setIsLoading(true)
        await addTicket(customerName, customerEmail, event.id, bookingRef, parseInt(event.quantity)).then((_) => {
          sendEmailRecipt(customerName, customerEmail, event, bookingRef)
        }).catch((error) => {
          setShowError({show: true, message: `An unexpected error occured: ${error.message}. Please contact us via our email admin@act-tanzania.org for support and we will assist you.`})
        })
        setIsLoading(false)
      } catch (error) {
        setShowError({show: true, message: `An unexpected error occured: ${error.message}. Please contact us via our email admin@act-tanzania.org for support and we will assist you.`})
      }
    }

    const queryParams = new URLSearchParams(window.location.search);
    
    const eventId       = queryParams.get('eventId')
    const eventName     = queryParams.get('eventName')
    const eventLocation = queryParams.get('eventLoaction')
    const eventDate     = queryParams.get('eventDate')
    const ticketCost    = queryParams.get('ticketCost')
    const customerName  = queryParams.get('userName')
    const customerEmail = queryParams.get('userEmail')
    const bookingRef    = queryParams.get('bookingRef')
    const quantity      = queryParams.get('ticketQuantity')

    if (eventId && eventName && eventLocation && eventDate && customerName && customerEmail && bookingRef) {
      const event = {
        id: eventId,
        name: eventName,
        location: eventLocation,
        date: eventDate,
        ticketCost: ticketCost,
        quantity: quantity
      }
      setEvent(event)
      setBookingRef(bookingRef)

      if (ticketExists !== undefined && !ticketExists) {
        createTicket(customerName, customerEmail, event, bookingRef)        
      }
    } 
  }, [ticketExists])

  

  const sendEmailRecipt = (customerName, customerEmail, event, bookingRef) => {
    const formData = {
      to_name: customerName,
      to_email: customerEmail,
      message: `We are thrilled to inform you that your booking for ${event.name} has been successfully received!
          
      Your booking reference is: ${bookingRef} 
      
      Thank you for your support in raising money for the village Naumbu in Tanzania.\n

      Event Details:
      
      Date: ${getFormattedDate(event.date)}
      Time: ${getFormattedTime(event.date)}
      Location: ${event.location}`
    }
    

    emailjs.send(serviceId, templateId, formData, publicKey).then((response) => {
      console.log('Email sent successfully:', response)
      
    })
    .catch((error) => {
      setShowError({show: true, message: `Failed to send email receipt: ${error.message}. Please make note of your booking reference upon arrival: ${bookingRef}`})
    })
  }

  const didHideError = () => {
    setShowError({show: false, message: ''})
  }

  return (
    <div className="confirmation-page">
      <Alert 
        showAlert={showError.show} 
        title="Error" 
        description={showError.message}
      >
        <input className="button full-dark" type='button' value="Okay" onClick={didHideError} />
      </Alert>
      {!isLoading && content ? (
        <>
          <img className="confirmation-image" src={content.image} alt="happy child" />
          <img className="tick-image" src={content.paymentSuccessImage} alt="confirmation tick"/>
          <h1 className="title">{content.title}</h1>
          <p className="confirmation-text">{content.donationGratitudeText}</p>
          
          <PageSection
            sectionTitle='Booking summary'
            backgroundColor={alternateGray}
            foregroundColor={gray}
          >
            <div className="boooking-summary-container">
              <p>Booking complete. You should receive an email receipt for your booking and an email containing your booking reference with other booking information. If you did not receive an confirmation email, don’t hesitate to get in contact with us and email admin@act-tanzania.org. We will get back to you as soon as we can.</p>
              <div className="event-summary-container">
                <h3>{event?.name ?? ""}</h3>
                <p>{event?.description ?? "Big description of event"}</p>

                <div className="event-details">
                  <p>Date:</p>
                  <p className="event-details-text">{getFormattedDate(event?.date ?? "")}</p>
                  <p>Location:</p>
                  <p className="event-details-text">{event?.location ?? ""}</p>
                  <p className="event-details-text">Booking reference:</p>
                  <p className="event-details-text">{bookingRef}</p>
                </div>
              </div>

              <div className="event-summary-container">
                <div className="event-details">
                  <p>Ticket cost:</p>
                  <p className="event-details-text">{`£${event.ticketCost}`}</p>
                  <p>Boooking fee:</p>
                  <p className="event-details-text">{`£${bookingFee}`}</p>
                  <p>Number of tickets:</p>
                  <p className="event-details-text">{`x${event.quantity}`}</p>
                  <p className="event-details-text">Total:</p>
                  <p className="event-details-text">{`£${(parseFloat(event.ticketCost)* parseInt(event.quantity))+parseFloat(bookingFee)}`}</p>
                </div>
              </div>
              <Link className="button full-dark home-button" to="/">Home</Link>
            </div>
          </PageSection>
          <FindUsSection />
        </>
      ) : (
        <div className="loading-container">
          <LoadWheel />
          <p className="title">Loading...</p>
        </div>
      )}
    </div>
  )
}

export default ConfirmationPage