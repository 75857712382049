import NavigationBar from '../../components/NavigationBar/NavigationBar'
import FindUsSection from '../../components/FindUsSection/FindUsSection'
import PageHeaderView from '../../components/PageHeaderView/PageHeaderView'
import FundraisingEvent from '../../components/FundraisingEvent/FundraisingEvent'
import ProjectGallerySection from '../../components/ProjectGallerySection/ProjectGallerySection'
import PageSection from '../../components/PageSection/PageSection';
import { white, gray } from '../../helpers/colors';

import { useLocation } from "react-router-dom"
import ImpactSection from '../../components/ImpactSection/ImpactSection'

const FundraisingEventPage = () => {

  const location = useLocation()

  return (
    <>
      <NavigationBar currentPage={1} />
      <PageHeaderView 
        image={location.state.eventDetail.image} 
        title={location.state.eventDetail.title} 
        col1Text={location.state.eventDetail.description}
      />

      <PageSection
        sectionTitle=''
        backgroundColor={white}
        foregroundColor={gray}
      >
        <FundraisingEvent 
          title={location.state.eventDetail.title} 
          image={location.state.eventDetail.image} 
          description={location.state.eventDetail.col1Text}
          date={location.state.eventDetail.date}
          location={location.state.eventDetail.location}
          cost={location.state.eventDetail.cost}
          link={location.state.eventDetail.link}
        />

      </PageSection>

      {location.state.eventDetail.testimonial !== undefined && (
        <ImpactSection title='Review' testimonial={location.state.eventDetail.testimonial} />
      )}

      <ProjectGallerySection title='Gallery' images={location.state.eventDetail.galleryImages} />

      <FindUsSection />
    </>
  )
}

export default FundraisingEventPage