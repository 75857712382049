import { useEffect, useState } from 'react';
import { getPageContent } from '../../helpers/content';
import NavigationBar from '../../components/NavigationBar/NavigationBar'
import HomePageTitleSection from '../../components/HomePageTitleSection/HomePageTitleSection'
import ImageWheel from '../../components/ImageWheel/ImageWheel';
import OurProjectsSection from '../../components/OurProjectsSection/OurProjectsSection';
import FundraisingEvent from '../../components/FundraisingEvent/FundraisingEvent'
// import DonateSection from '../../components/DonateSection/DonateSection';
import BasicDonateSection from '../../components/BasicDonateSection/BasicDonateSection';
// import ContactSection from '../../components/ContactSection/ContactSection';
import FindUsSection from '../../components/FindUsSection/FindUsSection';
import PageSection from '../../components/PageSection/PageSection';
import { gray, white } from '../../helpers/colors';
import { getEvents } from '../../services/eventService'
import "./HomePage.css"

const HomePage = () => {

  const [content, setContent] = useState(null);
  const [currentEvents, setCurrentEvents] = useState([])

  useEffect(() => {
    document.title = 'ACT';
    getPageContent('HomePage').then(r => setContent(r))
  }, []);

  useEffect(() => {
    const loadEvents = () => {
      try {
        getEvents((events) => {
          // const today = new Date()
          // const currentEvents = events.filter((event) => new Date(event.ticketSaleExpiry) > today && event.isTest === undefined)
          // setCurrentEvents(currentEvents)
          setCurrentEvents([])
        })
      } catch (error) {
        alert(error)
      }
    }
    loadEvents()
  }, [])

  return (
    <>
      <NavigationBar />

      {content !== null &&
        <>
          <HomePageTitleSection title={content.title} subtitle={content.subtitle} quote={content.quote} />

          <ImageWheel images={content.imageWheel} />

          <OurProjectsSection
            title={content.activeProjectsTitle}
            displayingActiveProjects={true}
            projects={content.activeProjects}
          />

          {currentEvents.length > 0 && (
            <PageSection
              sectionTitle='Latest fundraising event'
              backgroundColor={white}
              foregroundColor={gray}
            >
              {currentEvents.map((event, key) => (
                  <>
                    {event["isTest"] === undefined && (
                      <FundraisingEvent 
                        key={key}
                        event={event}
                      />
                    )}
                  </>
                ))}
            </PageSection>
          )}
  
          <OurProjectsSection title={content.previousProjectsTitle} projects={content.previousProjects} />

          {/*
          <DonateSection />
          */}

          <BasicDonateSection />
          
          {/* <PageSection
            sectionTitle='Latest fundraising event'
            backgroundColor={white}
            foregroundColor={gray}
          >
            <FundraisingEvent 
              title={content.newsTitle} 
              image={content.newsImage} 
              description={content.newsDescription}
              date={content.newsDate}
              location={content.newsLocation}
              cost={content.newsCost}
              link={content.newsLink}
            />
          </PageSection> */}
          
          {/* <ContactSection /> */}
          <FindUsSection />
        </>
      }
    </>
  )

}

export default HomePage
