import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './OurProjectsSection.css';
import PageSection from '../PageSection/PageSection';
import ProjectCard from './ProjectCard/ProjectCard';
import rightArrow from '../../media/arrow.right.png';
import { gray, offWhite } from '../../helpers/colors';

/**
 * The Our Projects section will be responsible for displaying all the
 * projects that have been undertaken by ACT.
 *
 * props:
 *  title - The title of the section displaying the projects.
 *  projects - An array of all of the projects that have been completed
 *             for the charity.
 */
const OurProjectsSection = ({title, displayingActiveProjects, projects}) => {

    const DEFAULT_NUM_PROJECTS = 4

    const SHOULD_SHOW_EXPAND = projects.length > DEFAULT_NUM_PROJECTS;

    const CONTAINER_CLASS = SHOULD_SHOW_EXPAND ? 'projectsContainer' : 'activeProjectsContainer'

    const [projectsDisplaying, setProjectsDisplaying] = useState(DEFAULT_NUM_PROJECTS);

    /**
     * Component to display the button at the end of the form, which
     * allows users to show or hide projects.
     *
     * @param {*} props - {class, onClick, label}
     * @returns A button element.
     */
    function ShowHideButton(props) {
        return (
            <div className="moreProjectsCard">
                <button
                    className={props.class}
                    type='button'
                    onClick={props.onClick}
                    data-testid='show-more-less-button'
                >
                    {props.label}
                    {props.class === "moreProjectsButton" &&
                        <img className='rightArrowImage' src={rightArrow} alt="right arrow"/>
                    }
                </button>
            </div>
        );
    }

    return (
        <PageSection
        sectionTitle={title}
        backgroundColor={offWhite}
        foregroundColor={gray}>
            {displayingActiveProjects &&
                <h2 className="activeProjectsInfo" data-testid='active-projects-info'>
                    Any donations you are able to provide today will go towards
                    supporting new projects.
                </h2>
            }
            <div className={CONTAINER_CLASS}>
                {projects.map(function(project, index) {
                    if (index < projectsDisplaying) {
                        return <ProjectCard project={project} key={index}/>
                    }
                    return <div key={index} style={{display: 'none'}}></div>
                })}
                {projectsDisplaying < projects.length && SHOULD_SHOW_EXPAND &&
                    <div className='moreLessButtonContainer' data-testid='show-more-button'>
                        <ShowHideButton
                            class="moreProjectsButton"
                            onClick={() => setProjectsDisplaying(projectsDisplaying + 3)}
                            label="Show more"
                        />
                    </div>
                }
                {projectsDisplaying >= projects.length && SHOULD_SHOW_EXPAND &&
                    <div className='moreLessButtonContainer' data-testid='show-less-button'>
                        <ShowHideButton
                            class="hideProjectsButton"
                            onClick={() => setProjectsDisplaying(DEFAULT_NUM_PROJECTS)}
                            label="Hide projects"
                        />
                    </div>
                }
            </div>
        </PageSection>
    );
}

OurProjectsSection.defaultValues = {
    title: 'Our Projects',
    displayingActiveProjects: false,
    projects: []
}

OurProjectsSection.propTypes = {
    title: PropTypes.string,
    displayingActiveProjects: PropTypes.bool,
    projects: PropTypes.array
}

export default OurProjectsSection;
