import './App.css';
import HomePage from '../Home/HomePage'
import AboutPage from '../About/AboutPage'
import VolunteeringPage from '../Volunteering/VolunteeringPage'
import ContactPage from '../Contact/ContactPage'
import PaymentPage from '../Payment/PaymentPage'
import FundraisingPage from '../Fundraising/FundraisingPage'
import FundraisingEventPage from '../FundraisingEventPage/FundraisingEventPage'
import BookEventPage from '../BookEvent/BookEventPage'
import ConfirmationPage from '../Confirmation/ConfirmationPage'
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom'
import ProjectPage from '../Project/ProjectPage'
import TestBooking from '../TestBooking/TestBooking';
import JSquaredSignatureView from '../../components/JSquaredSignature/JSquaredSignature'

function App() {
  return (
    <div className="App">
      {/* Set url path routes for each page of the interface */}
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/about' element={<AboutPage />} />
          <Route path='/volunteering' element={<VolunteeringPage />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/project' element={<ProjectPage />} />
          <Route path='/payment' element={<PaymentPage />} />
          <Route path='/fundraising' element={<FundraisingPage />} />
          <Route path='/fundraising-event' element={<FundraisingEventPage />} />
          <Route path='/book-event'        element={<BookEventPage />} />
          <Route path='/confirmation'      element={<ConfirmationPage />} />
          <Route path='/mock-event' element={<TestBooking />} />
        </Routes>
        <Link className='mock-event-link' to={'/mock-event'}><p className='charity-number'>Charity number: 1199800</p></Link>
      </BrowserRouter>

      
      <JSquaredSignatureView />
    </div>
  );
}

export default App;
