import PropTypes from 'prop-types'
import './TrusteeMemberCard.css'

const TrusteeMemberCard = ({ trustee }) => {
  return (
    <div className='trustee-info-container'>
      <div className='bullet-point'></div>

      <div className='trustee-info'>
        <p><b>{trustee.name}</b></p>
        <p className='trustee-job-description'><i>{trustee.position}</i></p>
        <p className='trustee-job-description'>{trustee.job}</p>
      </div>
    </div>
  )
}

export default TrusteeMemberCard

TrusteeMemberCard.propTypes = {
  trustee: PropTypes.shape(
    {
      name: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
      job: PropTypes.string.isRequired
    }
  )
}
