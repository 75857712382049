import React from 'react';
import PropTypes from 'prop-types';
import Quote from '../../../media/quote.svg';
import './TeamMemberCard.css';

/**
 * The team member card is responsible for displaying a team
 * member in the 'Meet The Team' view.
 *
 * props:
 *  member - The team member to display information about.
 *           A team member must have a name, position, quote, and image.
 */
const TeamMemberCard = ({member}) => {
    const {name, position, quote, image} = member;
    return (
        <div className='teamMemberCard' data-testid='member-card'>
            <div>
              <div className='card-header'>
                <h2 className="teamMemberName"> {name} </h2>
                <img className='quoteImage' src={Quote} alt='quote' />
              </div>
              <h3 className='teamMemberPosition'> {position} </h3>
              <p className='teamMemberQuote'> {quote} </p>
            </div>
            <img src={image} className="teamMemberImage" alt={image}/>
        </div>
    );
}

TeamMemberCard.propTypes = {
    member: PropTypes.object
}

export default TeamMemberCard;
