/**
 * Get page Content in json readable form.
 *
 * @param {string} page - The page of the page to get content for.
 * @returns json data for page text content.
 */
export async function getPageContent(page) {
    const path = './content/' + page + '/content.json';
    const data = await fetch(path, OPTIONS)
    return data.json()
}

/**
 * Get Component content in json readable form.
 *
 * @param {*} component
 * @returns json data for component content.
 */
export async function getComponentContent(component) {
    const path = './content/PageComponents/' + component + '.json';
    const response = await fetch(path, OPTIONS);
    return response.json();
}

/**
 * Fetch options
 */
const OPTIONS = {
    headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
}
