import NavigationBar from "../../components/NavigationBar/NavigationBar"
import PageHeaderView from '../../components/PageHeaderView/PageHeaderView'
import FindUsSection from "../../components/FindUsSection/FindUsSection"
import PageSection from '../../components/PageSection/PageSection'
import Textfield from "../../components/Textfield/Textfield"

import CardDetailsSection from "../../components/CardDetailsSection/CardDetailsSection"
import { white, gray } from '../../helpers/colors'
import { useLocation } from "react-router-dom"
import { useState, useEffect, useRef } from "react"
import "./BookEventPage.css"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { v4 as uuidv4 } from 'uuid'
import { bookingFee, stripe_private_key, stripe_secret_key } from "../../helpers/constants"

const stripe = require("stripe")(stripe_secret_key);
const stripePromise = loadStripe(stripe_private_key);

const BookEventPage = () => {
  const [clientSecret, setClientSecret] = useState(undefined)
  const [ bookingRef, setBookingRef ] = useState('')

  const [userName, setUserName] = useState('')
  const [nameError, setNameError] = useState({message: '', isError: false})

  const [userEmail, setUserEmail] = useState('')
  const [emailError, setEmailError] = useState({message: '', isError: false})

  const [quantity, setQuantity] = useState('1')
  const [quantityError, setQuantityError] = useState({message: '', isError: false})

  const ticketInfoRef = useRef(null)
  const location   = useLocation()
 
  useEffect(() => {
    const getTicketCostTemp = () => {
      const charge = location.state.event.ticketCost
      const intQuantity = parseInt(quantity)
      if (!isNaN(intQuantity) && intQuantity >= 1) {
        return (charge*Math.abs(intQuantity)) + bookingFee 
      }
      return charge + bookingFee
    }

    const createIntent = async () => {
      console.log('secret');
      const bookingRef = uuidv4()
      setBookingRef(bookingRef)

      const paymentIntent = await stripe.paymentIntents.create({
        amount: getTicketCostTemp()*100,
        currency: "gbp",
        automatic_payment_methods: {
          enabled: true,
        },
        description: `${location.state.event.name}, Ref: ${bookingRef}`,
        metadata: {
          "booking_ref": bookingRef
        }
      })
      
      setClientSecret(paymentIntent['client_secret'])
    }
    createIntent()
  }, [location.state.event.ticketCost, location.state.event.name, quantity])

  useEffect(() => {
    const scrollToTicketInfo = () => {
      if (ticketInfoRef.current) {
        ticketInfoRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
    scrollToTicketInfo()
  }, [nameError.message, emailError.message, quantityError.message])

  const onNameChange = (value) => {
    if (value.length < 20) {
      setUserName(value)
    }
    setNameError({message: '', isError: false})
  }

  const onEmailChange = (value) => {
    if (value.length < 100) {
      setUserEmail(value)
    }
    setEmailError({message: '', isError: false})
  }

  const getQuantity = () => {
    const intQuantity = parseInt(quantity)
    if (!isNaN(intQuantity) && intQuantity >= 1) {
      return intQuantity
    }
    return 1
  }

  const getTicketCost = () => {
    const charge = location.state.event.ticketCost
    const intQuantity = parseInt(quantity)
    if (!isNaN(intQuantity) && intQuantity >= 1) {
      return (charge*Math.abs(intQuantity)) + bookingFee 
    }
    return charge + bookingFee
  }

  const appearance = {
    theme: 'stripe',
  }

  const options = {
    clientSecret,
    appearance,
  }

  return (
    <div>
      <NavigationBar currentPage={1}/>
      <PageHeaderView image={location.state.event.posterImage} title={`Book ${location.state.event.name}`} col1Text={location.state.event.description} />

      <PageSection
        sectionTitle='Booking overview'
        backgroundColor={white}
        foregroundColor={gray}
      >
        <div className="book-event-form">
          <div className="booking-costs">
          <p>{`${location.state.event.name}:`}</p>
          <p className="booking-info">{`£${location.state.event.ticketCost}`}</p>
          <p>Booking fee:</p>
          <p className="booking-info">{`£${bookingFee}`}</p>
          <p>Tickets:</p>
          <p className="booking-info">{`x${getQuantity()}`}</p>

          <p className="booking-info">Total:</p>
          <p className="booking-info">{`£${getTicketCost()}`}</p>
        </div>

        <div className='payment-form'>
          <h2 ref={ticketInfoRef}>Ticket information</h2>
          <div className="ticket-inputs-info">
            <Textfield
              label='Name'
              value={userName}
              placeholder='Enter your name'
              error={nameError}
              onChange={onNameChange}
            />

            <Textfield
              label='Email'
              value={userEmail}
              placeholder='Enter your email'
              error={emailError}
              onChange={onEmailChange}
            />
          </div>
          <div className="quantity-grid">
            <Textfield
              label='Number of tickets'
              value={quantity}
              type={'number'}
              placeholder='Ticket quantity'
              error={quantityError}
              onChange={setQuantity}
            />
          </div>
        </div>
          {clientSecret !== undefined && (
            <Elements options={options} stripe={stripePromise}>
              <CardDetailsSection
                usernameHandler={{value: userName, onError: setNameError}}
                emailHandler={{value: userEmail, onError: setEmailError}}
                quantityHandler={{value: quantity, onError: setQuantityError}}
                charge={getTicketCost()} 
                event={location.state.event} 
                bookingRef={bookingRef}
                clientSecret={clientSecret} 
              />            
            </Elements>
          )}
        </div>

      </PageSection>

      <FindUsSection />
    </div>
  )
}

export default BookEventPage