import { useEffect, useState } from 'react';
import { getPageContent } from '../../helpers/content';
import NavigationBar from '../../components/NavigationBar/NavigationBar'
import OurMissionSection from '../../components/OurMissionSection/OurMissionSection'
import PageHeaderView from '../../components/PageHeaderView/PageHeaderView'
import MeetTheTeamSection from '../../components/MeetTheTeamSection/MeetTheTeamSection'
// import ContactSection from '../../components/ContactSection/ContactSection'
import FindUsSection from '../../components/FindUsSection/FindUsSection'

const AboutPage = () => {

  const [content, setContent] = useState(null);

  useEffect(() => {
    document.title = 'About Us';
    getPageContent('AboutPage').then(r => setContent(r))
  }, []);

  return (
    <>
      <NavigationBar currentPage={2} />

      {content !== null &&
        <>
          <PageHeaderView video={content.video} title={content.title} col1Text={content.col1Text} col2Text={content.col2Text} />

          <OurMissionSection missionStatement={content.mission.statement} author={content.mission.author} />

          <MeetTheTeamSection team={content.team} trustees={content.trustees} />

          {/*
          <ContactSection />
        */}
          <FindUsSection />
        </>
      }
    </>
  )

}

export default AboutPage
