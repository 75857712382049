import { useState, useEffect } from "react"
import { getPageContent } from "../helpers/content";

const useContent = (title, page) => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    document.title = title
    getPageContent(page).then(r => setContent(r))
  }, [title, page])

  return [content]
}

export default useContent