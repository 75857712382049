import { useEffect, useState } from 'react';
import { getPageContent } from '../../helpers/content';
import NavigationBar from '../../components/NavigationBar/NavigationBar'
import PageHeaderView from '../../components/PageHeaderView/PageHeaderView';
// import ContactSection from '../../components/ContactSection/ContactSection';
import FindUsSection from '../../components/FindUsSection/FindUsSection';

const ContactPage = () => {

  const [content, setContent] = useState(null);

  useEffect(() => {
    document.title = "Contact Us"
    getPageContent('ContactPage').then(r => setContent(r))
  }, []);

  return (
    <>
      <NavigationBar currentPage={4} />

      {content !== null &&
        <>
          <PageHeaderView image={content.image} title={content.title} col1Text={content.col1Text} col2Text={content.col2Text} />

          
          {/* <ContactSection /> */}
        

          <FindUsSection />
        </>
      }
    </>
  )
}

export default ContactPage
