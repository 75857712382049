import FundraisingEvent from '../../components/FundraisingEvent/FundraisingEvent'
import { getPageContent } from '../../helpers/content'
import PageSection from '../../components/PageSection/PageSection'
import { white, gray } from '../../helpers/colors'
// import { getEvents } from '../../services/eventService'
import { getEvents } from '../../services/eventService'


import { useState, useEffect } from 'react'

const TestBooking = () => {

  const [content, setContent] = useState(null)
  const [currentEvents, setCurrentEvents] = useState([])

  useEffect(() => {
    document.title = "Fundraising"
    getPageContent('FundraisingPage').then(r => setContent(r))
  }, [])

  useEffect(() => {
    getEvents((events) => {
      const today = new Date()
      const currentFundraisingEvents = events.filter((event) => new Date(event.ticketSaleExpiry) > today)
      setCurrentEvents(currentFundraisingEvents)

    })
  }, [])

  return (
    <>
      {content !== null && (

        <PageSection
          sectionTitle='Latest test fundraising event'
          backgroundColor={white}
          foregroundColor={gray}
        >
          <p style={{textAlign: 'left', marginBottom: '2em'}}>
          The following fundraising event is not real and is only intended as a test simulation for the booking of real future events. However, since it is testing the payment system, you will be charged and the money will be transferred to the ACT metro bank account. To view this page again, click on the charity number at the bottom of the website.
          </p>
          {currentEvents.length > 0 ? (
            <>
              {currentEvents.map((event, key) => (
                <FundraisingEvent 
                  key={key}
                  event={event}
                  isSoldOut={false}
                />
              ))}
            </>
          ) : (
            <>
              <p style={{textAlign: 'left'}}>More events coming soon.</p>
              <br></br>
            </>
          )}
        </PageSection>
      )}
    </>
  )
}

export default TestBooking