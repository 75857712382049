import { db } from "./firebase"
import { collection, addDoc, onSnapshot, query } from "firebase/firestore"

const collectionName = 'tickets'

export const addTicket = async (name, email, eventId, bookingRef, quantity) => {
  const currentDate = new Date()
  const ticket = {
    ticketHolderName: name,
    email: email,
    eventId: eventId,
    bookingRef: bookingRef,
    isValid: true,
    quantity: quantity,
    dateSold: currentDate.toISOString()
  }
  const res = await addDoc(collection(db, collectionName), ticket)
  return res.id
}

export const getTicketsForEvent = (eventId, onTicketsSnapshot) => {
  const q = query(collection(db, collectionName))

  onSnapshot(q, (snapshot) => {
    const tickets = snapshot.docs.map((doc) => {
      const ticket = doc.data()

      return {
        ticketHolderName: ticket.ticketHolderName,
        email:            ticket.email,
        eventId:          ticket.eventId,
        isValid:          ticket.isValid,
        dateSold:         ticket.dateSold,
        bookingRef:       ticket.bookingRef
      }
    })
    const eventTickets = tickets.filter((ticket) => ticket.eventId === eventId)
    onTicketsSnapshot(eventTickets, undefined)
  }, error => {
    onTicketsSnapshot([], new Error(error.message))
  })
}

export const getTickets = (onTicketsSnapshot) => {
  const q = query(collection(db, collectionName))
  
  onSnapshot(q, (snapshot, e) => {
    const tickets = snapshot.docs.map((doc) => {
      const ticket = doc.data()

      return {
        ticketHolderName: ticket.ticketHolderName,
        email:            ticket.email,
        eventId:          ticket.eventId,
        isValid:          ticket.isValid,
        dateSold:         ticket.dateSold,
        bookingRef:       ticket.bookingRef
      }
    })
    onTicketsSnapshot(tickets, undefined)
  }, error => {
    onTicketsSnapshot([], new Error(error.message))
  })
}