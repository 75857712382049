import PageSection from '../PageSection/PageSection';
import '../DonateSection/DonateSection.css'

/**
 * The BasicDonateSection component is responsible for displaying the basic donate section
 * which consists of a title and a single button linking to the stewardship donation page.
 *
 */
const BasicDonateSection = () => {

  return (
    <PageSection
      sectionTitle="Donate"
      backgroundColor='#515A60'
      foregroundColor='#F9FBFC'>
        <div className='donation-section-container'>
          <p className='donation-extra-info'>Any donation would be greatly appreciated. </p>

          <a
            className='button full-light'
            href="https://www.stewardship.org.uk/partners/20406643"
            target="_blank"
            rel="noreferrer"
            data-testid='donate-button'
          >
            Donate
          </a>

        </div>
    </PageSection>
  )
}

export default BasicDonateSection
