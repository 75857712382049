import PageSection from '../PageSection/PageSection';
import ReactMarkdown from "react-markdown";
import PropTypes from 'prop-types'
import { gray, alternateGray } from '../../helpers/colors';
import './ImpactSection.css'

/**
 * Impact section displays a testimonial from a Tanzanian person related to a
 * project. Impact section displays the name, job, image and testimonial.
 *
 * props:
 *  testimonial - Testmonial object to be displayed.
 *
 */
const ImpactSection = ({ title, testimonial }) => {
  return (
    <PageSection
      sectionTitle={title}
      backgroundColor={alternateGray}
      foregroundColor={gray}>

      <div className='impact-section-container'>
        <div className='impact-header'>
          <div>
            <h2>{testimonial.name}</h2>
            <p className='testimonial-job'>{testimonial.job}</p>
          </div>

          <img className='testimonial-image' src={testimonial.image} alt='testimonial' />
        </div>

        <ReactMarkdown>
          {testimonial.testimonial}
        </ReactMarkdown>
      </div>
    </PageSection>
  )
}

ImpactSection.defaultProps = {
  title: "Our Impact"
}

ImpactSection.propTypes = {
  title: PropTypes.string.isRequired,
  testimonial: PropTypes.shape({
    name: PropTypes.string.isRequired,
    job: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  })
}

export default ImpactSection
