// Methods
import PropTypes from 'prop-types'

// Style
import './Alert.css'

/**
 * Alert is responsible for displaying an alert to the screen. Alert containes
 * a title, description and child components.
 * 
 * @prop {string} title       - title of the alert
 * @prop {string} description - text under title
 * @prop {componnet} children - react components to be displayed on alert
 * @prop {bool} showAlert     - controls if alert is displayed
 * @prop {bool} alignLeft     - controls if alert components are aligned left otherwise
 *                              they align centre
 * 
 */
const Alert = ({ title, description, children, showAlert, alignLeft }) => {
  return (
    <div className={showAlert ? 'alert alert-show' : 'alert alert-hide'} data-testid='alert'>
      <div style={{textAlign: alignLeft ? 'left' : 'center'}}>
        {/* Alert title */}
        <h2 className='alert-title'>{title}</h2>
        <p className='alert-description'>{description}</p>
        <div className='child-components'>
          {children}
        </div>
      </div>
    </div>
  )
}

Alert.defaultProps = {
  showAlert: false,
  alignLeft: false
}

Alert.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  showAlert: PropTypes.bool,
  alignLeft: PropTypes.bool
}

export default Alert