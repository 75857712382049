import React from 'react';
import './JSquaredSignature.css'

/**
 * The J^2 signature view will be displayed at the bottom of all
 * pages across the website.
 */
const JSquaredSignatureView = () => {
    return (
        <div className='viewStyle'>
            <p className='textStyle'> Website produced by J^2 Tech Solutions © 2022 </p>
        </div>
    );
}

export default JSquaredSignatureView;
