import React from 'react';
import './FindUsSection.css';
import '../OurProjectsSection/ProjectCard/ProjectCard.css';
import FacebookIcon from '../../media/facebook-icon.svg';
// import InstagramIcon from '../../media/instagram-icon.svg';
// import LinkedInIcon from '../../media/linkedin-icon.svg';
import EmailIcon from '../../media/mail-icon.svg';
import PDF from '../../media/gdpr-notice.pdf'

/**
 * The find us section displays some information on how to get
 * in touch with the charity.
 */
const FindUsSection = () => {
    return (
        <div className='findUsSectionContainer'>
            <div data-testid='email-section'>
                <h3 className='projectCardTitle findUsTitle'> Get in touch directly </h3>
                <div className='findUsInfo'>
                    <div className='findUsItem email'>
                        <img src={EmailIcon} alt='email-icon' />
                        <p>darren@act-tanzania.org</p>
                    </div>
                </div>
                {/* <h3 className='projectCardTitle findUsTitle'>Check out our Tanzania partner charity</h3>
                <a className='findUsItem' href='https://www.somasalama.org/'>
                    <p><u>Soma Salama</u></p>
                </a> */}

                <h3 className='projectCardTitle findUsTitle'>Our privacy policy</h3>
                <a className='findUsItem' href={PDF}>
                    <p><u>Privacy policy</u></p>
                </a>
            </div>

            <div data-testid='follow-section'>
                <h3 className='projectCardTitle findUsTitle followUsTitle'> Follow us </h3>
            
                <div>
                    <a className='findUsItem social' href='https://www.facebook.com/actanzaniacharity'>
                        <p> Facebook </p>
                        <img src={FacebookIcon} alt='facebook-icon'/>
                    </a>
                    {/* <div className='findUsItem social'>
                        <p> Instagram </p>
                        <img src={InstagramIcon} alt='instagram-icon'/>
                    </div>
                    <div className='findUsItem social'>
                        <p> LinkedIn </p>
                        <img src={LinkedInIcon} alt='linkedin-icon'/>
                    </div> */}
                  </div>
                
            </div>

        </div>
    );
}

export default FindUsSection;
