import React from 'react';
import PropTypes from 'prop-types';
import EmbeddedVideo from '../EmbeddedVideo/EmbeddedVideo';
import ReactMarkdown from "react-markdown";
import './PageHeaderView.css';

/**
 * The page header view is displayed on all views with the
 * exception of the home page. An image/video will be displayed
 * at the top of the bar, with a title view and text being
 * displayed below.
 *
 * props:
 *  image - The header image.
 *  video - The video to be displayed in place of the header image.
 *  title - The title for the page.
 *  col1Text - The text to be placed in the first column.
 *  col2Text - The text to be placed in the second column.
 *             (Optional: if null, only one column will be displayed)
 */
const PageHeaderView = ({image, video, title, col1Text, col2Text, comingSoon}) => {

    return (
        <div className='pageHeaderViewContainer'>
            { image != null && <img className='headerImage' src={image} alt={title} /> }
            { video != null && <EmbeddedVideo url={video} /> }
            <h1 data-testid='title' className='projectTitle'> {title} </h1>
            { comingSoon &&
                <h3 className='comingSoonInfoLabel'> This project is coming soon </h3>
            }
            { col2Text != null &&
                <div className='textRow'>
                    <div className='textColumn'>
                      <ReactMarkdown className='display-linebreak'>
                        {col1Text}
                      </ReactMarkdown>
                    </div>
                    <div className='textColumn'>
                      <ReactMarkdown className='display-linebreak'>
                        {col2Text}
                      </ReactMarkdown>
                    </div>
                </div>
            }
            { col2Text == null && <p className='infoText'> {col1Text} </p> }
        </div>
    );
}

PageHeaderView.propTypes = {
    image: PropTypes.string,
    video: PropTypes.string,
    title: PropTypes.string,
    col1Text: PropTypes.string,
    col2Text: PropTypes.string,
    comingSoon: PropTypes.bool
}

export default PageHeaderView;
