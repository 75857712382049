import FundraisingEvent from '../../components/FundraisingEvent/FundraisingEvent'
import NavigationBar from '../../components/NavigationBar/NavigationBar'
import PageHeaderView from '../../components/PageHeaderView/PageHeaderView'
import FindUsSection from '../../components/FindUsSection/FindUsSection'
import { getPageContent } from '../../helpers/content'
import PageSection from '../../components/PageSection/PageSection'
import { alternateGray, white, gray } from '../../helpers/colors'
// import { getEvents } from '../../services/eventService'
import { getEvents } from '../../services/eventService'


import { useState, useEffect } from 'react'
import { getTickets } from '../../services/ticketService'

const FundraisingPage = () => {

  const [content, setContent] = useState(null)
  const [pastEvents, setPastEvents] = useState([])
  const [currentEvents, setCurrentEvents] = useState([])
  const [tickets, setTickets] = useState([])

  useEffect(() => {
    document.title = "Fundraising"
    getPageContent('FundraisingPage').then(r => setContent(r))
  }, [])

  useEffect(() => {
    getEvents((events) => {
      const today = new Date()
      const currentFundraisingEvents = events.filter((event) => new Date(event.ticketSaleExpiry) > today)
      setCurrentEvents(currentFundraisingEvents)
      // setCurrentEvents([])

      const pastEvents = events.filter((event) => new Date(event.ticketSaleExpiry) < today && event["isTest"] === undefined)
      setPastEvents(pastEvents)
    })

    getTickets((tickets) => {
      setTickets(tickets)
    })
  }, [])

  const isEventSoldOut = (event) => {
    const eventTickets = tickets.filter((ticket) => ticket.eventId === event.id)
    return eventTickets.length === event.totalAvailableTickets
  }

  return (
    <>
      <NavigationBar currentPage={1} />

      {content !== null && (
        <>
          <PageHeaderView image={content.image} title={content.title} col1Text={content.col1Text}  />

          <PageSection
            sectionTitle='Latest fundraising event'
            backgroundColor={white}
            foregroundColor={gray}
          >
            {currentEvents.length > 0 ? (
              <>
                {currentEvents.map((event, key) => (
                  <>
                    {event["isTest"] === undefined && (
                      <FundraisingEvent 
                        key={key}
                        event={event}
                        isSoldOut={isEventSoldOut(event)}
                      />
                    )}
                  </>
                ))}
              </>
            ) : (
              <>
                <p style={{textAlign: 'left'}}>More events coming soon.</p>
                <br></br>
              </>
            )}
          </PageSection>
    
          <PageSection
            sectionTitle='Past events'
            backgroundColor={alternateGray}
            foregroundColor={gray}
          >
            {pastEvents.length > 0 ? (
              <>
                {pastEvents.map((event, key) => (
                  <FundraisingEvent 
                    key={key}
                    event={event}
                    isCurrentEvent={false}
                  />
                ))}
              </>
            ) : (
              <>
                <p style={{textAlign: 'left'}}>No past events.</p>
                <br></br>
              </>
            )}
          </PageSection>
        </>
      )}

      <FindUsSection />
    </>
  )
}

export default FundraisingPage