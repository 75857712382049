import PropTypes from 'prop-types'
import './Button.css'

/**
 * The Button component is responsible for displaying a generic button.
 *
 * props:
 *  isFull - bool: Controls whether the button background is filled on or transparent
 *  isDark - bool: Controls the colour of the buttons text and border.
 *
 * isDark and isFull are used together to control the foreground, background
 * and border of the button.
 *
 * Example:
 * isFull=true,  isDark=true - dark background with light text
 * isFull=true,  isDark=false - light background with dark text
 * isFull=false, isDark=true - transparent background with dark text and border
 * isFull=false, isDark=false - transparent background with light text and border
 */
const Button = ({ isFull, isDark, text, onClick, type, disabled}) => {

  /**
    * Gets the button style classes.
  */
  const getButtonClass = () => {
    return `button ${isFull ? 'full-' : 'empty-'}${isDark ? 'dark' : 'light'}`
  }

  return (
    <input
      className={getButtonClass()}
      style={{opacity: disabled ? '0.4' : '1', cursor: disabled ? 'wait' : 'normal'}}
      type={type}
      value={text}
      onClick={onClick}
      data-testid='button'
    />
  )
}

Button.defaultProps = {
  isFull: true,
  isDark: true,
  text: 'Button',
  onClick: () => {},
  type: 'button',
  disabled: false
}

Button.propTypes = {
  isFull: PropTypes.bool,
  isDark: PropTypes.bool,
  text: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}

export default Button
