import Button from '../Button/Button'
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import './CardDetailsSection.css'
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { validateName, validateEmail, validateDigits } from '../../helpers/inputValidation';

/**
 * The find us section displays inputs to enter card details and donate a given
 * amount.
 *
 * props:
 *  donationAmount - int: value of money to be donated.
 *  onSubmit - func: called when donate button is chosen.
 */
const CardDetailsSection = (props) => {

  const {
    usernameHandler,
    emailHandler,
    quantityHandler,
    charge, 
    event, 
    bookingRef, 
    clientSecret
  } = props

  const [paymentError, setPaymentError] = useState('')
  const [isLoading, setIsLoading] = useState(false) 

  const stripe = useStripe()
  const elements = useElements()

  useEffect(() => {
    if (!stripe) {
      return
    }

    if (clientSecret === undefined) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setPaymentError("Payment succeeded!")
          break;
        case "processing":
          setPaymentError("Your payment is processing.")
          break;
        case "requires_payment_method":
          // setPaymentError("Your payment was not successful, please try again.")
          setPaymentError("")
          break;
        default:
          setPaymentError(`Something went wrong: ${paymentIntent.status}`)
          break;
      }
    });
  }, [stripe, clientSecret])

  const isNameValid = () => {
    const isValid = validateName(usernameHandler.value)
    
    usernameHandler.onError({message: "Invalid name: name must only contain letters and not be left blank", isError: !isValid})
    if (!isValid) {
      setPaymentError("Invalid ticket information: Please provide a valid name, email address and ticket quantity")
      // scrollToTicketInfo()
    }
    return isValid
  }

  const isEmailValid = () => {
    const isValid = validateEmail(emailHandler.value)
    emailHandler.onError({message: "Invalid email: please enter a valid email address", isError: !isValid})
    if (!isValid) {
      setPaymentError("Invalid ticket information: Please provide a valid name, email address and ticket quantity")
      // scrollToTicketInfo()
    }
    return isValid
  }

  const isQuantityValid = () => {
    var isValid = validateDigits(quantityHandler.value)
    isValid = isValid && parseInt(quantityHandler.value) >= 1 && parseInt(quantityHandler.value) < 30
    quantityHandler.onError({message: "Invalid ticket quantity: please enter a valid number between 1-30", isError: !isValid})

    if (!isValid) {
      setPaymentError("Invalid ticket information: Please provide a valid name, email address and ticket quantity")
      // scrollToTicketInfo()
    }
    return isValid
  }
  

  const handleSubmit = async (e) => {
    e.preventDefault()
    
    if (!stripe || !elements) {
      return;
    }

    const isValidName = isNameValid()
    const isValidEmail = isEmailValid()
    const isValidQuantity = isQuantityValid()

    if (!isValidName || !isValidEmail || !isValidQuantity) { return }

    setIsLoading(true);
    const dataToPass = {
      eventId: event.id, 
      userName: usernameHandler.value, 
      userEmail: emailHandler.value, 
      eventName: event.name,
      eventLoaction: event.location,
      eventDate: event.eventDate,
      bookingRef: bookingRef,
      ticketCost: event.ticketCost,
      ticketQuantity: quantityHandler.value
    }
    const origin = window.location.origin
    const returnUrl = `${origin}/confirmation?${new URLSearchParams(dataToPass)}`;

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: returnUrl,
        receipt_email: emailHandler.value,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setPaymentError(error.message);
    } else {
      setPaymentError("An unexpected error occurred: " + error.message)
    }

    setIsLoading(false)
  }

  const paymentElementOptions = {
    layout: 'tabs'
  }

  return (
    <>        
      <form className='payment-form' id="payment-form" onSubmit={handleSubmit}>
        <h2>Card details</h2>
        <PaymentElement id="payment-element" options={paymentElementOptions} />

        <Button text={isLoading || !stripe || !elements ? 'Loading...' : `Pay now £${charge}`} disabled={isLoading} type='submit'/>
        {/* Show any error or success messages */}
        {paymentError && <div className='error-message' id="payment-message">{paymentError}</div>}
      </form>
    </>
  )
}

CardDetailsSection.propTypes = {
  charge: PropTypes.number,
}

export default CardDetailsSection
