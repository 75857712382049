import React from 'react';
import PropTypes from 'prop-types';
import './ImageWheel.css'
import { useState, useEffect } from 'react';
import leftArrow from '../../media/arrow.left.svg';
import rightArrow from '../../media/arrow.right.svg';

/**
 * The Image wheel will display images that the user can scroll
 * through. This will be displayed below some of the views.
 * If not interacted with, the image will automatically scroll after
 * 5 seconds.
 *
 * props:
 *  images - the images to be displayed.
 *  delay - the time between image switches.
 */
const ImageWheel = ({images, delay}) => {

    const [imgIndex, setImgIndex] = useState(0);

    /**
     * Automatically set a new image after 10 seconds.
     */
    useEffect(() => {
        const int = setInterval(() => nextImage(true), delay * 1000);
        return () => clearInterval(int);
    })

    /**
     * Change the currently displayed image.
     *
     * @param {bool} up - Whether the image should go to next (true) or
     *                    previous (false) image.
     */
    const nextImage = (up) => {
        const next = up ? imgIndex + 1 : imgIndex - 1;
        const nextIndex = next < 0 ? images.length - 1 : next >= images.length ? 0 : next;
        setImgIndex(nextIndex);
    }

    return (
        <div className='imageWheelContainer'>
            <img
                src={images[imgIndex]}
                className="imageWheelImage"
                alt={images[imgIndex]}
                data-testid={images[imgIndex]}
            />
            <div className='imageIndicatorContainer'>
                {images.map(function(_, i) {
                    if (i === imgIndex) {
                        return(
                            <div
                                className='imageIndicator'
                                style={{opacity: 1}}
                                key={i}
                                data-testid='indicator'
                            />
                        )
                    } else {
                        return (
                            <div
                                className='imageIndicator'
                                style={{opacity: 0.5}}
                                onClick={() => setImgIndex(i)}
                                key={i}
                                data-testid='indicator'
                            />
                        )
                    }
                })}
            </div>
            <div className='navArrowContainer'>
                <img className='backArrow' src={leftArrow} onClick={() => nextImage(false)} alt='back-arrow'/>
                <img className='nextArrow' src={rightArrow} onClick={() => nextImage(true)} alt='next-arrow' />
            </div>
        </div>
    );

}

ImageWheel.defaultProps = {
    images: [],
    delay: 10
}

ImageWheel.propTypes = {
    images: PropTypes.array,
    delay: PropTypes.number
}

export default ImageWheel;
