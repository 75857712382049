import NavigationBar from '../../components/NavigationBar/NavigationBar'
import PageHeaderView from '../../components/PageHeaderView/PageHeaderView';
import CardDetailsSection from '../../components/CardDetailsSection/CardDetailsSection'
import FindUsSection from '../../components/FindUsSection/FindUsSection';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react'
import { getPageContent } from '../../helpers/content';
import './PaymentPage.css'

const PaymentPage = () => {

  const [ content, setContent ] = useState(null)
  const [ paymentComplete, setPaymentComplete ] = useState(false)

  useEffect(() => {
      document.title = 'Donate'
      getPageContent('PaymentPage').then(r => setContent(r))
  }, [])

  const location = useLocation()

  const didDonate = () => {
    setPaymentComplete(true)
  }

  return (
    <>

      { content !== null && (
        <>
        <NavigationBar currentPage={4} />

        <PageHeaderView
          image={content.image}
          title={paymentComplete ? '' : content.title}
          col1Text={paymentComplete ? '' : content.donationText}
        />

        {/*
          * Display payment complete text when payment successful.
          * Display card detail form when payment has not been made.
        */}
        { paymentComplete ? (
          <>
            <img src={content.paymentSuccessImage} alt='Tick icon' />
            <h1 className='title'>{content.donationSuccessTitle}</h1>
            <h2 className='title donation-gratitude-text'>{content.donationGratitudeText}</h2>
          </>
        ) : (
          <CardDetailsSection donationAmount={location.state.donation} onSubmit={didDonate}/>
        )}

        <FindUsSection />
        </>
      )}
    </>
  )
}

export default PaymentPage
