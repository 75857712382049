import Logo from '../../media/logo.png'
import MobileMenuIcon from '../../media/mobile-menu-icon.svg'
import CloseMenuIcon from '../../media/close-icon.svg'
import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import './NavigationBar.css'

/**
 * The Navigation Bar component will be responsible for displaying
 * the navigation bar and enabling the user to switch between main pages.
 *
 * props:
 *  currentPage - integer representing the index of the selected page on the
 *                navigation bar.
 */
const NavigationBar = ({ currentPage }) => {

  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const pageNames = ['Home', 'Fundraising events', 'About', 'Volunteering', 'Contact']
  const pageLinks = ['/', '/fundraising', '/about', '/volunteering', '/contact']
  const navbarRef = useRef(null)

  /**
    * Flips the show mobile menu variable.
  */
  const didSelectMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu)
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    document.addEventListener("mousedown", didClickOutsideNav);
      return () => {
        document.removeEventListener("mousedown", didClickOutsideNav);
    }
  }, [navbarRef])

  /**
    * Hides the mobile nav.
  */
  const didClickOutsideNav = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setShowMobileMenu(false)
    }
  }

  return (
      <div className='nav-bar' data-testid='nav-bar' ref={navbarRef}>
        <Link to='/' alt='home page'>
          <img className='logo' data-testid='logo' src={Logo} alt='act logo' style={{marginBottom: '-6px'}}/>
        </Link>
        <img className='nav-burger-menu' src={MobileMenuIcon} onClick={didSelectMobileMenu} alt='menu'/>

        <div className={showMobileMenu ? 'nav-items' : 'nav-items nav-items-hide'}>
          <img className='close-menu-button' src={CloseMenuIcon} onClick={didSelectMobileMenu} alt='close menu'/>

          {pageNames.map((name, key) => (
              <div key={key} data-testid={`element-${key}`}>
                  <Link className='nav-item' to={pageLinks[key]}><li>{name}</li></Link>

                  { key === currentPage && (
                      <div className='underline' />
                  )}
              </div>
          ))}

          <a
            className='nav-donate-button temp-donate-button'
            href="https://www.stewardship.org.uk/partners/20406643"
            target="_blank"
            rel="noreferrer"
            data-testid='donate-button'
          >
          Donate
          </a>
        </div>
    </div>
  )
}

NavigationBar.defaultProps = {
  currentPage: 0
}

NavigationBar.propTypes = {
  currentPage: PropTypes.number
}

export default NavigationBar
