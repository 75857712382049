export const offWhite = '#F9FBFC';
export const white = '#FFFFFF';
export const gray = '#515A60';
export const darkGray = '#424242';
export const lightGray = '#8A8A8A';
export const black = '#000000';
export const infoGray = '#767676';
export const selectionGray = '#272C2F';
export const borderColor = '#E0D1D1';
export const alternateGray = '#F2F3F3';
export const crimson = "#DC143C";