import { useEffect, useState } from 'react';
import { getPageContent } from '../../helpers/content';
import NavigationBar from '../../components/NavigationBar/NavigationBar'
import PageHeaderView from '../../components/PageHeaderView/PageHeaderView';
import FindUsSection from '../../components/FindUsSection/FindUsSection';

import { addVolenteer } from '../../services/volenteerService';
import { offWhite, gray } from '../../helpers/colors';
import Textfield from '../../components/Textfield/Textfield'
import { validateEmail } from '../../helpers/inputValidation';
import PageSection from '../../components/PageSection/PageSection';

const VolunteeringPage = () => {

  const [content, setContent] = useState(null);
  const [name, setName] = useState("")

  const [nameError, setNameError] = useState("")
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")
  const [reason, setReason] = useState("")
  const [reasonError, setReasonError] = useState("")
  const MAX_REASON_CHARS = 200

  useEffect(() => {
    document.title = 'Volunteering';
    getPageContent('VolunteeringPage').then(r => setContent(r))
  }, []);

  const onChangeName = (value) => {
    if (value.length <= 80) {
      setName(value)
    }
  }

  const onChangeEmail = (value) => {
    if (value.length <= 100) {
      setEmail(value)
    }
  }

  const onChangeReason = (value) => {
    if (value.length <= MAX_REASON_CHARS) {
      setReason(value)
    }
  }

  const isValidName = () => {
    const isValid = name !== ""
    if (!isValid) {
      setNameError("Name must not be left blank")
    } else {
      setNameError("")
    }
    return isValid
  }

  const isValidEmail = () => {
    const isValid = validateEmail(email)
    if (!isValid) {
      setEmailError("Invalid email")
    } else {
      setEmailError("")
    }
    return isValid
  }

  const isValidReason = () => {
    const isValid = reason !== ""
    if (!isValid) {
      setReasonError("Reason for signing up must not be left blank")
    } else {
      setReasonError("")
    }
    return isValid
  }

  const submitButtonPressed = (e) => {
    e.preventDefault()
    const validName = isValidName()
    const validEmail = isValidEmail()
    const validReason = isValidReason()

    if (validName && validEmail && validReason) {
      addVolenteer({
        name: name, 
        email: email,
        reason: reason
      })
      setName("")
      setEmail("")
      setReason("")
      alert("Thank you for registring an interst in volunteering with us! \n\nYou will hear from us soon.")
    }
  }


  return (
    <>
      <NavigationBar currentPage={3}/>
      {content !== null &&
        <>

          {/* <PageHeaderView image={content.image} title={content.title} col1Text={content.col1Text} col2Text={content.col2Text} /> */}
          <PageHeaderView
            image={content.image}
            title={content.title}
            col1Text={content.col1Text}
          />

          <PageSection
            sectionTitle="Join us in 2025"
            backgroundColor={offWhite}
            foregroundColor={gray}
          >
            <form className='contact-section-container' onSubmit={submitButtonPressed}>
              <div className='name-email-flex-container'>
                <Textfield
                  placeholder='Enter name'
                  label='NAME:'

                  onChange={onChangeName}
                  value={name}
                  error={nameError}
                />
                <Textfield
                  placeholder='Enter email'
                  label='EMAIL:'
                  onChange={onChangeEmail}
                  value={email}
                  error={emailError}
                />
              </div>
             
              <Textfield
                placeholder='Reason for volentering'
                label={`REASON (${reason.length}/${MAX_REASON_CHARS}):`}
                isTextarea={true}
                onChange={onChangeReason}
                value={reason}
                error={reasonError}
              />
              <br/>
              <div style={{textAlign: "left"}}>
                <input className='button full-dark' type='submit' value='Send' />
              </div>
            </form>
          </PageSection>
          <FindUsSection />
        </>
      }
    </>
  )

}

export default VolunteeringPage
