import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAvGYXc6dwsfPGWhocWm2z3uepZM6Sj8_A",
  authDomain: "act-events-b0997.firebaseapp.com",
  projectId: "act-events-b0997",
  storageBucket: "act-events-b0997.appspot.com",
  messagingSenderId: "214512834490",
  appId: "1:214512834490:web:2420c720d594cff96a2f83",
  measurementId: "G-M1GRS6DEJD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

export { db }