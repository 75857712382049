import React from "react";
import PropTypes from 'prop-types'
import ReactPlayer from "react-player";

/**
 * The embedded video component uses the react-player library
 * to embed a YouTube video in a view.
 *
 * props:
 *  url - The url of the video to embed.
 */
const EmbeddedVideo = ({url}) => {
    return (
        <ReactPlayer url={url} width="100%" />
    )
}

EmbeddedVideo.defaultProps = {
    url: 'https://www.youtube.com/watch?v=beao3iKVS3w&t=10s'
}

EmbeddedVideo.propTypes = {
    url: PropTypes.string
}

export default EmbeddedVideo;
