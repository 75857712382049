import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import NavigationBar from '../../components/NavigationBar/NavigationBar'
import PageHeaderView from '../../components/PageHeaderView/PageHeaderView'
// import ContactSection from '../../components/ContactSection/ContactSection'
import FindUsSection from '../../components/FindUsSection/FindUsSection'
import ImpactSection from '../../components/ImpactSection/ImpactSection'
import ProjectGallerySection from '../../components/ProjectGallerySection/ProjectGallerySection'
// import DonateSection from '../../components/DonateSection/DonateSection'
import BasicDonateSection from '../../components/BasicDonateSection/BasicDonateSection'


const ProjectPage = () => {

  const location = useLocation();

  const { project } = location.state;

  useEffect(() => {
    document.title = `Project - ${project.title}`
  })

  return (
    <>
      <NavigationBar currentPage={0} />

      <PageHeaderView image={project.imageBanner} title={project.title} col1Text={project.col1Text} col2Text={project.col2Text} comingSoon={project.comingSoon} />

      {project.testimonial !== undefined && (
        <ImpactSection testimonial={project.testimonial} />
      )}

      <ProjectGallerySection images={project.galleryImages} />

      {/*
        <DonateSection extraInfo={info} approx={project.donationApprox}/>
        */}

      <BasicDonateSection />

      {/*
          <ContactSection />
        */}

      <FindUsSection />
    </>
  )
}

export default ProjectPage;
