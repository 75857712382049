
const NAME_REGEX = /^[a-zA-Z -]+$/

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const MESSAGE_REGEX = /^[a-zA-Z0-9$@$£!%?&#;,:-_. +()"'/<>]+$/

const DIGIT_REGEX = /^[0-9]+$/ 

/**
 * Validate a name entered by the user.
 *
 * @param {string} name - The name to validate
 * @returns True if the name is valid.
 */
export const validateName = (name) => {
    return NAME_REGEX.test(String(name));
}

/**
 * Validate an email entered by the user.
 *
 * @param {string} email - The email to validate.
 * @returns True if the email is valid.
 */
export const validateEmail = (email) => {
    return EMAIL_REGEX.test(String(email).toLowerCase())
}

export const validateDigits = (digits) => {
    return DIGIT_REGEX.test(String(digits))
}

/**
 * Validate a message entered by the user.
 *
 * @param {string} message - The message to validate.
 * @returns True if the message is valid.
 */
export const validateMessage = (message) => {
    return MESSAGE_REGEX.test(String(message))
}
