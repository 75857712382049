import React from 'react';
import PropTypes from 'prop-types';
import './PageSection.css';

/**
 * The page section component contains a title and a
 * background. This will be the starting template for each page section
 * of the web app.
 *
 * props:
 *  sectionTitle - Title for that section.
 *  foregroundColor - The colour for the title.
 *  backgroundColor - The colour of the background for this section.
 */
const PageSection = ({sectionTitle, foregroundColor, backgroundColor, children}) => {
    return (
        <div data-testid='page-section-wrapper'
            style={{color: foregroundColor, backgroundColor: backgroundColor}}>
            <div className='pageSection'>
                <h2 className='sectionTitle'> {sectionTitle} </h2>
                {children}
            </div>
        </div>
    );
}

PageSection.propTypes = {
    sectionTitle: PropTypes.string,
    foregroundColor: PropTypes.string,
    backgroundColor: PropTypes.string,
}

export default PageSection;
