export const getFormattedDate = (dateString) => {
  const date = new Date(dateString)
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  const month = date.getMonth()+1 < 10 ? `0${date.getMonth()+1}` : date.getMonth()+1
  return `${day}/${month}/${date.getFullYear()}` 
}

export const getFormattedTime = (dateString) => {
  const date = new Date(dateString)
  const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  return `${hours}:${minutes}` 
}

