import React from 'react';
import PropTypes from 'prop-types';
import TeamMemberCard from './TeamMemberCard/TeamMemberCard';
import TrusteeMemberCard from './TrusteeMemberCard/TrusteeMemberCard';
import PageSection from '../PageSection/PageSection';
import './MeetTheTeamSection.css';
import { alternateGray, gray } from '../../helpers/colors';

/**
 * The Meat the team section will display all of the current
 * team members that form the charity.
 *
 * props:
 *  team - an array of team member objects.
 */
const MeetTheTeamSection = ({ team, trustees }) => {
    return (
        <PageSection
        sectionTitle="Meet The Team"
        backgroundColor={alternateGray}
        foregroundColor={gray}>
            <div className='meetTheTeamContainer'>
                {team.map(function(member, index) {
                    return <TeamMemberCard key={index} member={member} />
                })}
            </div>

            <h2 className='meet-trustees-title'>Meet the trustees</h2>
            <div className='trustees-container'>
              {trustees.map((trustee, key) => (
                <TrusteeMemberCard key={key} trustee={trustee} />
              ))}
            </div>
        </PageSection>
    );
}

MeetTheTeamSection.defaultProps = {
    team: []
}

MeetTheTeamSection.propTypes = {
    team: PropTypes.array
}

export default MeetTheTeamSection;
