import React from 'react';
import PropTypes from 'prop-types';
import './HomePageTitleSection.css';

/**
 * The HomePageTitleView is responsible for
 * displaying the title, subtitle and quote that will be
 * displayed on the home page.
 *
 * Props:
 *  title - The title
 *  subtitle - Text that will be displayed below the title.
 *  quote - A quote that is displayed below the subtitle view.
 */
const HomePageTitleSection = ({title, subtitle, quote}) => {
    return (
        <div className='titleSectionContainer'>
            <h1 className='title' data-testid='title'> {title} </h1>
            <p className='subtitle' data-testid='subtitle'> {subtitle} </p>
            <h2 className='quote' data-testid='quote'> {quote} </h2>
        </div>
    );
}

HomePageTitleSection.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    quote: PropTypes.string
}

export default HomePageTitleSection;
