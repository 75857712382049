import { db } from "./firebase"
import { collection, addDoc } from "firebase/firestore"

const collectionName = 'volenteer'

export const addVolenteer = async (volenteer) => {
  await addDoc(collection(db, collectionName), {
    name: volenteer.name,
    email: volenteer.email,
    reason: volenteer.reason
  })
}