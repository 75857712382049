import { db } from "./firebase"
import { collection, doc, updateDoc, addDoc, deleteDoc, query, orderBy, onSnapshot } from "firebase/firestore"

const collectionName = 'events'

/**
 * Adds an event to the database.
 * 
 * @param event to be added to the firestore
 * 
 * @throws error when failing to add to db
 */
export const addEvent = async (event) => {
  await addDoc(collection(db, collectionName), {
    name:                  event.name,
    description:           event.description,
    eventDate:             event.eventDate,
    ticketSaleExpiry:      event.ticketSaleExpiry,
    ticketCost:            event.ticketCost,
    posterImage:           event.posterImage,
    totalAvailableTickets: event.totalAvailableTickets
  })
}

/**
 * Gets all fundraising events.
 * 
 * @returns list of all events
 */
export const getEvents = (onEventsSnapshot) => {
  const q = query(collection(db, collectionName), orderBy('eventDate', 'desc'))

  onSnapshot(q, (snapshot) => {
    const events = snapshot.docs.map((doc) => {
      const event = doc.data()

      return {
        id:                    doc.id,
        name:                  event.name,
        description:           event.description,
        eventDate:             event.eventDate,
        ticketSaleExpiry:      event.ticketSaleExpiry,
        ticketCost:            event.ticketCost,
        posterImage:           event.posterImage,
        totalAvailableTickets: event.totalAvailableTickets,
        location:              event.location,
        isTest:                event.isTest
      }
    })    
    onEventsSnapshot(events)
  })
}

/**
 * Updates an event.
 * 
 * @param event to be updated
 * 
 * @throws error when failing to update firestore
 */
export const updateEvent = async (event) => {
  if (!event.id) { return }
  
  await updateDoc(doc(db, collectionName, event.id), {
      name:                  event.name,
      description:           event.description,
      eventDate:             event.eventDate,
      ticketSaleExpiry:      event.ticketSaleExpiry,
      ticketCost:            event.ticketCost,
      posterImage:           event.posterImage,
      totalAvailableTickets: event.totalAvailableTickets
  })
}

/**
 * Deletes an event.
 * 
 * @param event to be deleted
 * 
 * @throws error when failing to delete document in firestore
 */
export const deleteEvent = async (eventId) => {
  await deleteDoc(doc(db, collectionName, eventId))
}