import { darkGray } from "../helpers/colors"

const LoadWheel = () => {
  return (
    <svg className='load-wheel-icon' width="30" height="30" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.85 22.0001C2.93065 25.0674 4.97889 27.7004 7.68609 29.5024C10.3933 31.3044 13.6128 32.1777 16.8595 31.9909C20.1062 31.804 23.2043 30.567 25.6869 28.4663C28.1695 26.3656 29.9021 23.515 30.6237 20.344C31.3453 17.173 31.0168 13.8533 29.6877 10.8852C28.3586 7.91715 26.1009 5.4614 23.2547 3.88802C20.4086 2.31463 17.1282 1.70885 13.9078 2.16194C10.6874 2.61503 7.70152 4.10245 5.4 6.40008" stroke={darkGray} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default LoadWheel
