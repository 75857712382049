import React from 'react';
import PropTypes from 'prop-types';
import './ProjectGallerySection.css';
import '../OurProjectsSection/OurProjectsSection.css';
import PageSection from '../PageSection/PageSection';
import { gray, white } from '../../helpers/colors';

/**
 * The ProjectGallerySection will hold all of the images for a specific
 * project.
 *
 * props:
 *  images - An array of images to display.
 */
const ProjectGallerySection = ({ title, images }) => {
    return (
        <PageSection
        sectionTitle={title}
        backgroundColor={white}
        foregroundColor={gray}>
            <div className='projectsContainer'>
                {images.map(function(image, index) {
                    return (
                        <div className='galleryImageContainer' key={index} data-testid='gallery-img'>
                            <img className='galleryImage' src={image.image} alt={image.description}/>
                        </div>
                    )
                })}
            </div>
        </PageSection>
    );
}

ProjectGallerySection.defaultProps = {
    title: "Project Gallery"
}

ProjectGallerySection.propTypes = {
    title: PropTypes.string,
    images: PropTypes.array,
}

export default ProjectGallerySection;
